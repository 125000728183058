import {
  featureCollection,
  clone,
  booleanWithin,
  flatten,
  distance,
  union,
  intersect,
  lineIntersect,
  lineOverlap,
} from "@turf/turf";

export function objectProperties(
  objectType,
  properties = {},
  additionalProperties = {}
) {
  return Object.assign(
    { id: new Date().getTime() },
    properties,
    objectType.object.options,
    { objectType: objectType._id },
    additionalProperties
  );
}

export const validateOtherTypes = function (feature, otherFeature, options) {
  const type = options[feature.properties.objectType];
  const otherType = options[otherFeature.properties.objectType];

  if (
    type.behavior &&
    type.behavior.otherTypes &&
    type.behavior.otherTypes.indexOf(otherType._id) !== -1
  ) {
    return true;
  } else if (
    otherType.behavior &&
    otherType.behavior.otherTypes &&
    otherType.behavior.otherTypes.indexOf(type._id) !== -1
  ) {
    return true;
  }
  return false;
};

export const validateBuildings = function (feature, otherFeature, options) {
  const type = options[feature.properties.objectType];
  return type.behavior && type.behavior.buildings;
};

export const validateAllBehavior = (
  newObjects,
  collection,
  buildings,
  obstacles,
  objectTypes
) => {
  const invalid = {};
  validatePositions(
    newObjects,
    buildings,
    validateBuildings,
    objectTypes
  ).forEach((f) => {
    if (!invalid[f]) {
      invalid[f] = ["building"];
    }
  });

  // validatePositions(newObjects, obstacles, function() { return false }, objectTypes).forEach((f) => {
  // 	if (!invalid[f]) {
  // 		invalid[f] = ['obstacle']
  // 	}
  // })
  validatePositions(
    newObjects,
    collection,
    validateOtherTypes,
    objectTypes
  ).forEach((f) => {
    if (!invalid[f]) {
      invalid[f] = ["otherObject"];
    } else {
      invalid[f].push("otherObject");
    }
  });

  return invalid;
};

export const validatePositions = (
  collection,
  otherCollection,
  validOverlapCallback,
  validOverlapArguments
) => {
  if (collection.type !== "FeatureCollection") {
    collection = featureCollection(collection);
  }
  const invalid = [];
  for (let i = 0; i < collection.features.length; i++) {
    const feature = collection.features[i];
    if (
      !validatePosition(
        feature,
        otherCollection,
        validOverlapCallback,
        validOverlapArguments
      )
    ) {
      invalid.push(feature.properties.id);
    }
  }
  return invalid;
};

export const validatePosition = (
  feature,
  otherCollection,
  validOverlapCallback,
  validOverlapArguments
) => {
  for (let j = 0; j < otherCollection.features.length; j++) {
    const otherFeature = otherCollection.features[j];

    if (feature.properties.id === otherFeature.properties.id) continue;
    const overlap = doTheyOverlap(feature, otherFeature);
    if (overlap) {
      return validOverlapCallback(feature, otherFeature, validOverlapArguments);
    }
  }
  return true;
};

// Unify overlapping areas of the same kind, remove points too close to each other.
// @todo use doTheyOverlap and make it smarter according to object type
export const removeOverlaps = (collection) => {
  const newCollection = featureCollection([]);
  // let found = false
  collection.features.forEach((f) => {
    let feature = clone(f);
    collection.features.forEach((otherFeature) => {
      if (
        feature &&
        otherFeature &&
        feature.geometry.type == otherFeature.geometry.type &&
        otherFeature.properties.objectType === feature.properties.objectType
      ) {
        if (
          feature &&
          (feature.geometry.type == "Polygon" ||
            feature.geometry.type == "MultiPolygon") &&
          (otherFeature.geometry.type == "Polygon" ||
            otherFeature.geometry.type == "MultiPolygon")
        ) {
          try {
            if (doTheyOverlap(feature, otherFeature)) {
              const composite = union(feature, otherFeature);
              composite.properties = feature.properties;
              feature = composite;
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    });
    if (feature) {
      newCollection.features.push(feature);
    }
  });

  return newCollection;
};

export const doTheyOverlap = (feature, otherFeature) => {
  if (
    feature.geometry.type === "Point" &&
    otherFeature.geometry.type === "Point" &&
    distance(feature, otherFeature) < 0.001
  ) {
    return true;
  }

  if (
    feature.geometry.type === "Polygon" &&
    otherFeature.geometry.type === "Polygon"
  ) {
    try {
      const is = intersect(feature, otherFeature);
      if (!is) return false;
      else {
        return is.geometry.type.indexOf("LineString") === -1;
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (
    feature.geometry.type === "Polygon" &&
    otherFeature.geometry.type === "MultiLineString"
  ) {
    try {
      const is = lineIntersect(feature, otherFeature);
      return is;
    } catch (e) {
      console.log(e);
    }
  }

  if (
    feature.geometry.type !== "Point" &&
    otherFeature.geometry.type !== "Point"
  ) {
    try {
      return lineIntersect(feature, otherFeature).features.length > 0;
    } catch (e) {
      console.log(e);
    }
  }

  if (
    feature.geometry.type === "Point" &&
    otherFeature.geometry.type !== "Point"
  ) {
    if (
      otherFeature.geometry.type === "MultiPolygon" ||
      otherFeature.geometry.type === "MultiLineString"
    ) {
      flatten(otherFeature).features.forEach((f) => {
        if (booleanWithin(feature, f)) {
          return true;
        }
      });
    } else return booleanWithin(feature, otherFeature);
  }

  if (
    otherFeature.geometry.type !== "Point" &&
    feature.geometry.type === "Point"
  ) {
    if (
      feature.geometry.type === "MultiPolygon" ||
      feature.geometry.type === "MultiLineString"
    ) {
      flatten(feature).features.forEach((f) => {
        if (booleanWithin(otherFeature, f)) {
          return true;
        }
      });
    } else return booleanWithin(otherFeature, feature);
  }
};

export const areTheSame = (feature, otherFeature) => {
  if (
    feature.geometry.type == otherFeature.geometry.type &&
    feature.properties.objectType == otherFeature.properties.objectType
  ) {
    if (
      feature.geometry.type === "Point" &&
      otherFeature.geometry.type === "Point" &&
      distance(feature, otherFeature) < 0.001
    ) {
      return true;
    }
    if (
      otherFeature.geometry.type !== "Point" &&
      feature.geometry.type !== "MultiPolygon"
    ) {
      if (otherFeature.geometry.type === "MultiPolygon") {
        flatten(otherFeature).features.forEach((f) => {
          if (booleanWithin(feature, f)) {
            return true;
          }
        });
      } else if (booleanWithin(feature, otherFeature)) {
        return true;
      }
    }
    if (
      JSON.stringify(feature.geometry.coordinates) ==
      JSON.stringify(otherFeature.geometry.coordinates)
    ) {
      return true;
    }
  }
  return false;
};

// Remove duplicate entries based on geometry and object type
export const removeDupes = (collection) => {
  const newCollection = featureCollection([]);
  collection.features.forEach((feature) => {
    let insert = true;
    for (let i = 0; i < newCollection.features.length; i++) {
      const otherFeature = newCollection.features[i];
      insert = !areTheSame(feature, otherFeature);

      if (!insert) {
        break;
      }
    }
    if (insert) {
      newCollection.features.push(feature);
    }
  });
  return newCollection;
};

export const cropOutOfBounds = (collection, boundaries) => {
  const validObjects = featureCollection([]);
  if (collection.features.length == 0) return [];
  for (let i = 0; i < collection.features.length; i++) {
    const o = collection.features[i];
    if (o.geometry.type == "Polygon" || o.geometry.type == "LineString") {
      if (booleanWithin(o, boundaries)) {
        validObjects.features.push(o);
      } else {
        let int = false;
        try {
          int = intersect(o, boundaries);
        } catch {
          int = false;
        }
        if (int) {
          int.properties = o.properties;
          validObjects.features.push(int);
        }
      }
    } else if (o.geometry.type == "MultiLineString") {
      const validFeatures = featureCollection([]);
      flatten(o).features.forEach((f) => {
        if (booleanWithin(f, boundaries)) {
          validFeatures.features.push(f);
        } else {
          const is = lineOverlap(f, boundaries);
          if (is) {
            validFeatures.features.push(is);
          }
        }
      });
      validFeatures.features.forEach((f) => {
        validObjects.features.push(f);
      });
    } else if (o.geometry.type == "Point" && booleanWithin(o, boundaries)) {
      validObjects.features.push(o);
    }
  }
  return validObjects;
};
