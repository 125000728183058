<template>
  <div
    :id="id"
    :ref="id"
    v-bind:style="{ width: width + 'px', height: height + 'px' }"
  ></div>
</template>

<script>
import * as turf from "@turf/turf";

import mapboxgl from "mapbox-gl";
import MapboxDraw from "@maupalantir/mapbox-gl-draw";
import defaultDrawThemes from "@maupalantir/mapbox-gl-draw/src/lib/theme";
import {
  createEl,
  zoomButtons,
  drawingButton,
  predefinedObjectButton,
  defaultObjectButton,
  buildingObjectButton,
} from "../mapButtons";
import { createDrawMode } from "../mapObjectTypes";
import { draggableSymbols, symbolLayer } from "../mapHelpers";

export default {
  name: "MapWidget",
  props: {
    id: String,
    mapboxStyle: {
      type: String,
      default: "mapbox://styles/maupalantir/ckeflqtq61zso19nt4zk13q32",
    },
    editable: Boolean,
    zoomable: Boolean,
    draggable: { type: Boolean, default: false },
    width: Number,
    height: Number,
    boundaries: {
      type: Object,
      default: function () {
        return {};
      },
    },
    objects: {
      type: Object,
      default: function () {
        return { type: "FeatureCollection", features: [] };
      },
    },
    predefined: {
      type: Object,
      default: function () {
        return { type: "FeatureCollection", features: [] };
      },
    },
    displayOnly: {
      type: Object,
      default: function () {
        return { type: "FeatureCollection", features: [] };
      },
    },
    existing: {
      type: Object,
      default: function () {
        return { type: "FeatureCollection", features: [] };
      },
    },
    showDisplayOnlyIcons: {
      type: Boolean,
      default: true,
    },
    defaultZoom: Number,
    rotate: {
      type: Number,
      default: 0,
    },
    center: {
      type: Array,
      default: function () {
        return [];
      },
    },
    objectTypes: Object,
    additionalProperties: Object,
    generator: Object,
  },
  data: function () {
    return {
      map: null,
      modes: Object.assign(MapboxDraw.modes, {}),
      iconimages: [],
      iconsPut: false,
      iconsLoadRetries: 0,
      controls: [],
      modeSelected: false,
      listener: null,
      zoomed: false,
    };
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;
    this.map = new mapboxgl.Map({
      container: this.id, // container id
      style: this.mapboxStyle, // stylesheet location
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    });
    this.map.jumpTo({ center: this.centerPoint, zoom: this.zoom });
    this.map.rotateTo(this.rotate, { duration: 0 });

    if (this.zoomable) {
      this.map.scrollZoom.enable();
      this.zoomControls = zoomButtons(this.map);
      this.map.setMinZoom(3);
    } else if (this.editable) {
      this.map.setMinZoom(15.2);
      this.map.setMaxZoom(23);

      this.map.scrollZoom.disable();
      this.map.doubleClickZoom.disable();
      // this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
      this.zoomControls = zoomButtons(this.map);
    } else {
      this.map.setMinZoom(15.2);
      this.map.setMaxZoom(23);
      this.map.scrollZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.dragPan.disable();
    }

    this.map.on("load", () => {
      if (this.boundaries && !this.map.getSource("boundary")) {
        this.setBoundaries();
      }

      if (this.displayOnly && !this.map.getSource("display-only-nbs")) {
        this.setDisplayOnly();
      }

      if (this.existing && !this.map.getSource("existing-nbs")) {
        this.setExisting();
      }
    }); // load

    this.map.on("zoom", () => {
      this.zoomed = true;
    });

    this.map.on("draw.create", (e) => {
      this.modeSelected = false;
      this.updateAndEmit(e, "added");
    });

    this.map.on("draw.delete", () => {
      this.objectsInternalPrevious = turf.clone(this.objectsInternal);
      this.objectsInternal.features = this.draw.getAll().features; // .features[objectIndex].geometry = layer.toGeoJSON().geometry;
      this.emit("delete", null);
    });

    this.map.on("draw.update", (e) => this.updateAndEmit(e, "updated"));
  },
  watch: {
    boundaries: function (value) {
      // this.boundaries = turf.lineToPolygon(turf.lineString(val.geometry.coordinates[0]), {autoComplete: true});
      if (value) {
        const data = { type: "FeatureCollection", features: [value] };

        if (this.map.getSource("boundary")) {
          this.map.getSource("boundary").setData(data);
        } else {
          this.setBoundaries();
        }
        this.map.jumpTo({ center: this.centerPoint, zoom: this.zoom });
      }
    },
    center: function () {
      this.map.jumpTo({ center: this.centerPoint, zoom: this.zoom });
    },
    defaultZoom: function () {
      this.map.zoomTo(this.zoom);
    },
    objects: function (value) {
      if (this.draw && value && value.features) {
        this.featureIds = this.draw.set(value);
      }
      this.iconLayers(true);
    },
    existing: function () {
      this.setExisting();
    },
    displayOnly: function () {
      this.setDisplayOnly();
      this.iconLayers(true);
    },
    objectTypes: function () {
      this.setDisplayOnly();
      this.setExisting();
    },
    rotate: function () {
      this.map.rotateTo(this.rotate, { duration: 0 });
    },
  },
  computed: {
    objectsInternal() {
      if (this.objects.features.length < 1) {
        return { type: "FeatureCollection", features: [] };
      }
      return turf.clone(this.objects);
    },
    zoom() {
      return this.zoomed ? this.map.getZoom() : this.defaultZoom || 15.2;
    },
    centerPoint() {
      if (this.center && this.center.length == 2) {
        return this.center;
      }
      if (this.boundaries) {
        const center = turf.centerOfMass(this.boundaries);
        return center.geometry.coordinates;
        // return latLng(center.geometry.coordinates[1], center.geometry.coordinates[0]);
      } else {
        return [47, 19];
      }
    },
    bounds() {
      if (this.boundaries) {
        return turf.bbox(this.boundaries);
      }
      return [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ];
    },
  },
  methods: {
    resetListener() {
      if (this.cleanupCallback) {
        this.cleanupCallback();
      }
      if (this.listener) {
        this.map.getCanvas().style.cursor = "";
        this.map.off("click", this.listener);
        this.listener = null;
      }
    },
    updateAndEmit(event, eventType) {
      const errors = [];
      this.objectsInternalPrevious = turf.clone(this.objectsInternal);

      event.features.forEach((f) => {
        const type = this.objectTypes[f.properties.objectType];

        if (!type) errors.push("error");
        if (type && type.object.predefined) {
          errors.push("error_predefined");
        }
        if (type && type.object.editable === false) {
          if (event.action === "change_coordinates") {
            errors.push("error_not_editable");
          }
        }
      });

      if (!errors.length) {
        this.objectsInternal.features = this.draw.getAll().features; // .features[objectIndex].geometry = layer.toGeoJSON().geometry;
        this.emit(eventType, turf.featureCollection(event.features));
      } else {
        errors.forEach((e) => {
          this.emit(e, null);
        });
      }
    },
    setBoundaries() {
      this.map.addSource("boundary", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [this.boundaries] },
      });

      this.map.addLayer({
        id: "site-boundary",
        type: "line",
        source: "boundary",
        layout: {
          visibility: "visible",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#000",
          "line-width": 4,
        },
      });

      // this.map.addLayer({
      // 	'id': 'site-boundary-fill',
      // 	'type': 'fill',
      // 	'source': 'boundary',
      // 	'layout': {
      // 		'visibility': 'visible',
      // 				// 	'paint': {
      // 		'fill-color': '#000',
      // 		'fill-opacity': 0
      // 	}
      // });

      // // this.map.on('mouseenter', 'site-boundary-fill', () => {
      // // 	//this.map.dragPan.disable()
      // // });

      // // // When the mouse leaves the state-fill layer, update the feature state of the
      // // // previously hovered feature.
      // // this.map.on('mouseleave', 'site-boundary-fill', () => {
      // // 	this.map.dragPan.enable()
      // // });
    },
    setExisting() {
      if (!this.map.getSource("existing-nbs")) {
        this.map.addSource("existing-nbs", {
          type: "geojson",
          data: this.existing,
        });
      } else {
        this.map.getSource("existing-nbs").setData(this.existing);
      }
      if (!this.map.getLayer("existing-nbs-2")) {
        this.map.addLayer({
          id: "existing-nbs-2",
          type: "circle",
          source: "existing-nbs",
          filter: ["all", ["==", "$type", "Point"]],

          paint: {
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              // zoom is 5 (or less) -> circle radius will be 1px
              16,
              1,
              // zoom is 10 (or greater) -> circle radius will be 5px
              16.01,
              2,
            ],
            "circle-color": ["get", "color"],
            "circle-stroke-width": 0,
            "circle-stroke-color": ["get", "color"],
            "circle-blur": 0,
            "circle-opacity": 1,
          },
        });
      }
    },

    setDisplayOnly() {
      if (!this.map.getSource("display-only-nbs")) {
        this.map.addSource("display-only-nbs", {
          type: "geojson",
          data: this.displayOnly,
        });
      } else {
        this.map.getSource("display-only-nbs").setData(this.displayOnly);
      }

      if (!this.map.getLayer("display-only-nbs")) {
        this.map.addLayer({
          id: "display-only-nbs",
          type: "fill",
          filter: ["all", ["==", "$type", "Polygon"]],
          source: "display-only-nbs",
          paint: {
            "fill-color": ["get", "color"],
            "fill-antialias": true,
            "fill-opacity": 0.2,
            // 'raster-opacity': 0.5
          },
        });
      }

      if (!this.map.getLayer("display-only-nbs-line")) {
        this.map.addLayer({
          id: "display-only-nbs-line",
          type: "line",
          filter: ["all", ["==", "$type", "LineString"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          source: "display-only-nbs",
          paint: {
            "line-color": ["get", "color"],
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              // zoom is 5 (or less) -> circle radius will be 1px
              16.5,
              ["*", ["get", "width"], 1],
              // zoom is 10 (or greater) -> circle radius will be 5px
              16.6,
              ["*", ["get", "width"], 3],
            ],
          },
        });
      }
      if (!this.map.getLayer("display-only-nbs-2")) {
        this.map.addLayer({
          id: "display-only-nbs-2",
          type: "circle",
          source: "display-only-nbs",
          filter: ["all", ["==", "$type", "Point"]],

          paint: {
            "circle-radius": this.showDisplayOnlyIcons
              ? 0
              : [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  // zoom is 5 (or less) -> circle radius will be 1px
                  16,
                  1,
                  // zoom is 10 (or greater) -> circle radius will be 5px
                  16.01,
                  2,
                ],
            "circle-color": ["get", "color"],
            "circle-stroke-width": 0,
            "circle-stroke-color": ["get", "color"],
            "circle-blur": 0,
            "circle-opacity": this.showDisplayOnlyIcons ? 0.2 : 1,
          },
        });
      }

      if (this.showDisplayOnlyIcons) {
        this.loadIcons()
          .then(() => {
            symbolLayer(this.map, this.displayOnly, "displayOnly");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    // Load icon images
    loadIcons() {
      return new Promise((resolve, reject) => {
        if (!this.objectTypes) reject("No object types");
        let loaded = 0;
        Object.values(this.objectTypes).forEach((type) => {
          let blob;
          let url;
          if (this.iconimages.indexOf(type._id) == -1) {
            if (type.object.icon && typeof type.object.icon === "object") {
              blob = new Blob([type.object.icon.data.buffer]);
              url = URL.createObjectURL(blob);
            } else {
              url = "/images/icons/" + type._id + ".png";
            }

            this.map.loadImage(url, (error, image) => {
              loaded += 1;
              if (!error) {
                try {
                  this.map.addImage(type._id, image);
                } catch (e) {
                  console.log(e);
                  console.log(type._id);
                }
                this.iconimages.push(type._id);
                // this.iconLayer(type._id)
              }
              if (blob) {
                URL.revokeObjectURL(blob);
              }
              if (loaded == Object.values(this.objectTypes).length) resolve();
            });
          }
        });
      });
    },
    iconLayer(type, refresh) {
      const data = turf.featureCollection(
        this.objects.features.filter((f) => {
          return f.properties.objectType == type;
        })
      );

      draggableSymbols(
        this.map,
        data,
        type,
        this.objectTypes[type].object.type,
        refresh,
        (e, id, oldCenter) => {
          this.objectsInternalPrevious = turf.clone(this.objectsInternal);
          let index = false;
          let feature = false;
          for (let f = 0; f < this.objectsInternal.features.length; f++) {
            if (this.objectsInternal.features[f].properties.id == id) {
              index = f;
              feature = this.objectsInternal.features[f];
              break;
            }
          }
          if (feature) {
            const newCenter = turf.point([e.lngLat.lng, e.lngLat.lat]);

            if (feature.type === "Point") {
              this.objectsInternal.features[index] = newCenter;
            } else {
              oldCenter = turf.point([oldCenter.lng, oldCenter.lat]);
              const bearing = turf.rhumbBearing(oldCenter, newCenter);
              const distance = turf.distance(oldCenter, newCenter);
              this.objectsInternal.features[index] = turf.transformTranslate(
                this.objectsInternal.features[index],
                distance,
                bearing
              );
              this.objectsInternal.features[index].id = id;
            }

            this.emit(
              "objectMoved",
              turf.featureCollection([this.objectsInternal.features[index]])
            );
          }
        }
      );
    },
    // Create layers for the draggable icons.
    iconLayers(refresh = false) {
      if (this.iconimages.length > 0) {
        this.iconimages.forEach((type) => {
          this.iconLayer(type, refresh);
        });
      } else if (this.iconsLoadRetries < 10) {
        this.iconsLoadRetries += 1;
        setTimeout(this.iconLayers, 2000);
      }
    },
    metersPerPixel() {
      if (!this.map) {
        return 0;
      } else {
        return (
          (40075016.686 *
            Math.abs(Math.cos((this.map.getCenter().lat * Math.PI) / 180))) /
          Math.pow(2, this.map.getZoom() + 8)
        );
      }
    },
    // Should be created before draw control is added to map.
    addObjectModes() {
      for (const t in this.objectTypes) {
        if (this.objectTypes.hasOwnProperty(t)) {
          if (!this.objectTypes[t].object.fixed) {
            const mode = createDrawMode(
              this.objectTypes[t],
              this.additionalProperties
            );
            if (mode) this.modes["draw_" + t] = mode;
          }
        }
      }
    },
    addDraw() {
      if (this.draw) {
        this.map.removeControl(this.draw);
      } else {
        const themes = [
          {
            id: "gl-draw-outline",
            type: "line",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Polygon"],
              ["==", "user_fill", "false"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": ["get", "user_color"],
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                // zoom is 5 (or less) -> circle radius will be 1px
                16.5,
                ["*", ["get", "user_width"], 1],
                // zoom is 10 (or greater) -> circle radius will be 5px
                16.6,
                ["*", ["get", "user_width"], 3],
              ],
            },
          },
          {
            id: "gl-draw-poly",
            type: "fill",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Polygon"],
              ["!=", "user_fill", "false"],
            ],
            paint: {
              "fill-color": ["get", "user_color"],
              // 'fill-opacity': 1,
              //							'fill-outline-color': ['get', "user_color"],
              //							'fill-antialias': false,
              "fill-opacity": 0.5,
            },
          },
          {
            id: "gl-draw-line",
            type: "line",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "LineString"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": ["get", "user_color"],
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                // zoom is 5 (or less) -> circle radius will be 1px
                16.5,
                ["*", ["get", "user_width"], 1],
                // zoom is 10 (or greater) -> circle radius will be 5px
                16.6,
                ["*", ["get", "user_width"], 3],
              ],
            },
          },
          {
            id: "gl-draw-circle",
            type: "circle",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Point"],
            ],
            paint: {
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                // zoom is 5 (or less) -> circle radius will be 1px
                15.3,
                1,
                // zoom is 10 (or greater) -> circle radius will be 5px
                16,
                ["*", ["get", "user_radius"], 5],
              ],
              "circle-color": ["get", "user_color"],
              "circle-stroke-width": 0,
              "circle-stroke-color": ["get", "user_color"],
              "circle-blur": 0,
              "circle-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                // zoom is 5 (or less) -> circle radius will be 1px
                15.2,
                1,
                // zoom is 10 (or greater) -> circle radius will be 5px
                16.3,
                1,
              ],
            },
          },
        ];
        const overrided = defaultDrawThemes
          .filter((theme) => !themes.map(({ id }) => id).includes(theme.id))
          .concat(themes);

        this.draw = new MapboxDraw({
          userProperties: true,
          displayControlsDefault: false,
          controls: {
            //			polygon: true,
            trash: true,
          },
          styles: overrided,
        });
        this.draw.modes = this.modes;
      }
      this.map.addControl(this.draw, "top-left");
      this.featureIds = this.draw.set(this.objects);
    },
    // Add dynamic buttons for object types to the map.
    objectTypeControls() {
      const container = createEl("div", "mapboxgl-ctrl mapboxgl-ctrl-group");

      if (this.controls) {
        this.controls.forEach((c) => {
          this.map.removeControl(c);
        });
      }

      for (const t in this.objectTypes) {
        if (this.objectTypes.hasOwnProperty(t)) {
          let button = null;
          if (this.objectTypes[t].object.predefined) {
            button = predefinedObjectButton(
              this,
              this.objectTypes[t],
              container
            );
          } else if (
            this.objectTypes[t].behavior &&
            this.objectTypes[t].behavior.buildings
          ) {
            button = buildingObjectButton(this, this.objectTypes[t], container);
          } else if (!this.objectTypes[t].object.fixed) {
            button = drawingButton(this, this.objectTypes[t], container);
          } else {
            button = defaultObjectButton(this, this.objectTypes[t], container);
          }

          if (button) {
            this.map.addControl(button, "top-left");
            this.controls.push(button);
          }
        }
      }

      this.addDraw();
    },
    emit(event = null, newObjects) {
      this.$emit(
        "input",
        newObjects,
        turf.clone(this.objectsInternal),
        turf.clone(this.objectsInternalPrevious),
        event
      );
    },
  },
};
</script>

<style>
#map {
}
.mapboxgl-map button {
  color: black;
  border-radius: 0;
}
.mapboxgl-map .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}
</style>
