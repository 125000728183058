import DrawLineString from '@maupalantir/mapbox-gl-draw/src/modes/draw_line_string';
import {geojsonTypes, cursors, types, updateActions, modes, events} from '@maupalantir/mapbox-gl-draw/src/constants';
import simplify from "@turf/simplify";

const FreehandLine = Object.assign({}, DrawLineString)

FreehandLine.onSetup = function (opts) {
    opts = opts || {};
    const featureId = opts.featureId;

    let line;
    let currentVertexPosition;
    let direction = "forward";
    // if (featureId) {
    //   line = this.getFeature(featureId);
    //   if (!line) {
    //     throw new Error(
    //       "Could not find a feature with the provided featureId"
    //     );
    //   }
    //   let from = opts.from;
    //   if (
    //     from &&
    //     from.type === "Feature" &&
    //     from.geometry &&
    //     from.geometry.type === "Point"
    //   ) {
    //     from = from.geometry;
    //   }
    //   if (
    //     from &&
    //     from.type === "Point" &&
    //     from.coordinates &&
    //     from.coordinates.length === 2
    //   ) {
    //     from = from.coordinates;
    //   }
    //   if (!from || !Array.isArray(from)) {
    //     throw new Error(
    //       "Please use the `from` property to indicate which point to continue the line from"
    //     );
    //   }
    //   const lastCoord = line.coordinates.length - 1;
    //   if (
    //     line.coordinates[lastCoord][0] === from[0] &&
    //     line.coordinates[lastCoord][1] === from[1]
    //   ) {
    //     currentVertexPosition = lastCoord + 1;
    //     // add one new coordinate to continue from
    //     line.addCoordinate(
    //       currentVertexPosition,
    //       ...line.coordinates[lastCoord]
    //     );
    //   } else if (
    //     line.coordinates[0][0] === from[0] &&
    //     line.coordinates[0][1] === from[1]
    //   ) {
    //     direction = "backwards";
    //     currentVertexPosition = 0;
    //     // add one new coordinate to continue from
    //     line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
    //   } else {
    //     throw new Error(
    //       "`from` should match the point at either the start or the end of the provided LineString"
    //     );
    //   }
    // } else {
      line = this.newFeature({
        type: "Feature",
        properties: objectProperties(objectType, {}, additionalProperties),
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      });
      currentVertexPosition = 0;
      this.addFeature(line);
    //}

    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);

    // disable dragPan
    setTimeout(() => {
        if (!this.map || !this.map.dragPan) return;
        this.map.dragPan.disable();
    }, 0);

    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    this.activateUIButton(objectType._id);
    this.setActionableState({
      trash: true,
    });

    return {
      line,
      currentVertexPosition,
      direction,
    };
  };

FreehandLine.onDrag = FreehandLine.onTouchMove = function (state, e){
    state.dragMoving = true;
    this.updateUIClasses({ mouse: cursors.ADD });
    state.line.updateCoordinate(`${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
    state.currentVertexPosition++;
    state.line.updateCoordinate(`${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
}

FreehandLine.onMouseUp = function (state, e){
    if (state.dragMoving) {
        this.simplify(state.line);
        this.fireUpdate();
        this.changeMode(modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
    }
}

FreehandLine.onTouchEnd = function(state, e) {
    this.onMouseUp(state, e)
}

FreehandLine.fireUpdate = function() {

    this.map.fire(events.UPDATE, {
        action: updateActions.MOVE,
        features: this.getSelected().map(f => f.toGeoJSON())
    });
};

FreehandLine.simplify = function(line) {
  const tolerance = 1 / Math.pow(1.08, 10 * this.map.getZoom()) // https://www.desmos.com/calculator/nolp0g6pwr
  simplify(line, {
      mutate: true,
      tolerance: tolerance,
      highQuality: true
  });
}

FreehandLine.onStop = function (state) {
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    this.map.fire(events.CREATE, {
      features: [state.line.toGeoJSON()]
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(modes.SIMPLE_SELECT, {}, { silent: true });
  }

  setTimeout(() => {
    if (!this.map || !this.map.dragPan || this.map.dragPan) return;
    this.map.dragPan.enable();
  }, 0);
};
  
export default FreehandLine