import { featureCollection, clone, centroid, bbox } from "@turf/turf";
// import mapboxgl from 'mapbox-gl'

/**
 * Courtesy to @aerispaha
 * https://gist.github.com/aerispaha/826a9f2fbbdf37983dc01e6074ce7cd7
 */
export function zoomToFeature(map, feature) {
  // based on this: https://www.mapbox.com/mapbox-gl-js/example/zoomto-linestring/

  // Geographic coordinates of the LineString
  let coordinates;
  if (feature.type === "Polygon") {
    coordinates = feature.geometry.coordinates[0];
  } else {
    coordinates = feature.geometry.coordinates;
  }
  // Pass the first coordinates in the LineString to `lngLatBounds` &
  // wrap each coordinate pair in `extend` to include them in the bounds
  // result. A variation of this technique could be applied to zooming
  // to the bounds of multiple Points or Polygon geomteries - it just
  // requires wrapping all the coordinates with the extend method.
  const bounds = bbox(feature);
  map.fitBounds(bounds, {
    padding: 20,
  });
}

export function addHoverEffect(map, source, layer, clickCallback) {
  let hoveredStateId = null;

  // When the user moves their mouse over the state-fill layer, we'll update the
  // feature state for the feature under the mouse.
  const hoverMove = (e) => {
    if (e.features.length > 0) {
      if (hoveredStateId) {
        map.setFeatureState(
          { source: source, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = e.features[0].id || 0;
      map.setFeatureState(
        { source: source, id: hoveredStateId },
        { hover: true }
      );
    }
  };

  const hoverLeave = () => {
    if (hoveredStateId !== null) {
      map.setFeatureState(
        { source: source, id: hoveredStateId },
        { hover: false }
      );
    }
    hoveredStateId = null;
  };

  map.on("mousemove", layer, hoverMove);
  // When the mouse leaves the state-fill layer, update the feature state of the
  // previously hovered feature.
  map.on("mouseleave", layer, hoverLeave);

  // Placeholder if later interactivity is needed.
  map.on("click", layer, clickCallback);
  return { mouseleave: hoverLeave, mousemove: hoverMove };
}

export function symbolLayer(map, data, id) {
  const geojson = featureCollection([]);
  data.features.forEach((f) => {
    const p = centroid(f);
    p.id = f.id;
    p.properties.originalId = f.properties.id;
    p.properties.objectType = f.properties.objectType;
    geojson.features.push(p);
  });

  const layer = "icons-static-layer-" + id;
  const source = "icons-static-source-" + id;

  if (!map.getSource(source)) {
    map.addSource(source, {
      type: "geojson",
      data: geojson,
    });
  } else {
    map.getSource(source).setData(geojson);
  }

  if (!map.getLayer(layer)) {
    map.addLayer({
      id: layer,
      type: "symbol",
      source: source,
      layout: {
        "icon-image": ["get", "objectType"],
        "icon-allow-overlap": [
          "step",
          ["zoom"],
          ["boolean", true],
          16.4,
          ["boolean", true],
          18,
          ["boolean", true],
        ],
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // zoom is 5 (or less) -> circle radius will be 1px
          15.2,
          0.3,
          16,
          0.6,
          // zoom is 10 (or greater) -> circle radius will be 5px
          17,
          0.7,
        ],
        "text-field": "",
        "text-offset": [0, 0.6],
        "text-anchor": "top",
        "text-size": 14,
      },
      paint: {
        "text-opacity": 0.5,
      },
    });
  }
}

export function draggableSymbols(map, data, id, type, refresh) {
  const geojson = featureCollection([]);
  data.features.forEach((f) => {
    if (f.type === "Point") {
      geojson.features.push(clone(f));
    } else {
      const p = centroid(f);
      p.properties.id = f.properties.id;
      geojson.features.push(p);
    }
  });

  // var draggingId = false
  // let originalCoords = false
  // let canvas = map.getCanvas()
  const layer = "icons-layer-" + id;
  const source = "icons-source-" + id;

  //   let onMove = function(e) {
  // if (!draggingId) return;

  //       // Set a UI indicator for dragging.
  //       canvas.style.cursor = 'grabbing';
  //       upCallback(e, draggingId, originalCoords)
  //       // Update the Point feature in `geojson` coordinates
  //       // and call setData to the source layer `point` on it.
  //   }

  //   let onUp = function(e) {
  //       if (!draggingId) return;

  // upCallback(e, draggingId, originalCoords)
  //       //let coords = e.lngLat

  //       // Update form fields with coordinates
  //       canvas.style.cursor = '';
  //       draggingId = false;
  //       originalCoords = false;
  //   }

  //   let mouseDown = function(e) {
  // if (e.features) {
  // 	draggingId = e.features[0].properties.id
  // }
  // else {
  // 	draggingId = e.featureTarget.properties.id
  // }
  //       originalCoords = e.lngLat

  //       // Set a cursor indicator
  //       canvas.style.cursor = 'grab';
  //   }

  if (map.getLayer(layer) && refresh) {
    map.removeLayer(layer);
  }

  const overlap =
    type == "Point"
      ? [
          "step",
          ["zoom"],
          ["boolean", false],
          16.4,
          ["boolean", true],
          18,
          ["boolean", true],
        ]
      : true;

  const opacity =
    type == "Point"
      ? [
          "step",
          ["zoom"],
          ["number", 0.1],
          16.4,
          ["number", 1],
          18,
          ["number", 1],
        ]
      : 1;

  if (!map.getSource(source)) {
    map.addSource(source, {
      type: "geojson",
      data: geojson,
    });
  } else {
    map.getSource(source).setData(geojson);
  }

  if (!map.getLayer(layer)) {
    map.addLayer({
      id: layer,
      type: "symbol",
      source: source,
      layout: {
        "icon-image": id,
        "icon-allow-overlap": overlap,
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // zoom is 5 (or less) -> circle radius will be 1px
          15.2,
          0.3,
          16,
          0.4,
          // zoom is 10 (or greater) -> circle radius will be 5px
          17,
          0.9,
        ],
        "text-field": "",
        "text-offset": [0, 0.6],
        "text-anchor": "top",
        "text-size": 14,
      },
      paint: {
        // "icon-translate": [-6, -34],
        "text-opacity": opacity,
      },
    });

    // if (!map._delegatedListeners.mousedown || !map._delegatedListeners['mousedown'].find((li) => {
    // 	return li.layer == layer
    // })) {
    // // If a feature is found on map movement,
    // // set a flag to permit a mousedown events.
    // 	map.on('mouseenter', layer,  () => {
    // 	canvas.style.cursor = 'move';
    // 	});

    // 	map.on('mouseleave', layer,  () => {
    // 		canvas.style.cursor = '';
    // 	});

    // 	map.on('mousedown', layer, mouseDown);
    // 	map.on('mousemove', layer, onMove);
    // 	map.on('mouseup', onUp);
    // }

    // Set `true` to dispatch the event before other functions call it. This
    // is necessary for disabling the default map dragging behaviour.
  }
}
