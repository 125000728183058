import {
  addPredefinedObject,
  addBuildingObject,
  setDrawMode,
  generateObjects,
} from "./mapObjectTypes";

export function createEl(tagName, className, container) {
  const el = document.createElement(tagName);
  el.className = className || "";

  if (container) {
    container.appendChild(el);
  }
  return el;
}

export function objectButton(widget, objectType, container, callback) {
  const control = () => {
    return {
      onAdd: function () {
        this.button = createEl(
          "button",
          "mapboxgl-ctrl-type-" + objectType._id,
          container
        );
        this.icon = createEl(
          "span",
          "mapboxgl-ctrl-icon object-type-" + objectType._id,
          this.button
        );
        this.button.title = objectType.name;
        this.icon.innerHTML = objectType.object.icon
          ? objectType.object.icon
          : '<img src="/public/images/icons/' + objectType._id + '.png"/>';
        this.button.addEventListener("click", callback);
        return container;
      },
      onRemove: function () {
        this.button.remove();
        // element.parentNode.removeChild(element);
      },
    };
  };
  return new control();
}

export function predefinedObjectButton(widget, objectType, container) {
  return objectButton(widget, objectType, container, (event) => {
    event.preventDefault();
    addPredefinedObject(widget, objectType);
  });
}

export function buildingObjectButton(widget, objectType, container) {
  return objectButton(widget, objectType, container, (event) => {
    event.preventDefault();
    addBuildingObject(widget, objectType);
  });
}

export function drawingButton(widget, objectType, container) {
  return objectButton(widget, objectType, container, (event) => {
    event.preventDefault();
    if (widget.listener) {
      widget.map.off("mousedown", widget.listener);
      widget.listener = null;
    }
    setDrawMode(widget, objectType);
  });
}

export function defaultObjectButton(widget, objectType, container) {
  return objectButton(widget, objectType, container, (event) => {
    event.preventDefault();
    generateObjects(widget, objectType);
  });
}

export function zoomButtons(map, zoomLevels = null) {
  //	const zoomLevels = [15.2, 16.4, 18]
  if (!zoomLevels) {
    zoomLevels = [...Array(19).keys()];
    zoomLevels.shift();
  }

  const container = createEl("div", "mapboxgl-ctrl mapboxgl-ctrl-group");

  const zoomIn = function () {
    return {
      onAdd: function () {
        this.button = createEl("button", "mapboxgl-ctrl-zoom-in", container);
        this.icon = createEl("span", "mapboxgl-ctrl-icon", this.button);
        this.button.title = "Zoom in";
        this.button.addEventListener("click", (e) => {
          const z = map.getZoom();
          let i;
          for (i = 0; i < zoomLevels.length; i++) {
            if (zoomLevels[i] > z) {
              break;
            }
          }

          map.zoomTo(zoomLevels[i], { originalEvent: e });
        });
        return container;
      },
      onRemove: function () {
        this.button.remove();
      },
    };
  };

  const zoomOut = function () {
    return {
      onAdd: function () {
        this.button = createEl("button", "mapboxgl-ctrl-zoom-out", container);
        this.icon = createEl("span", "mapboxgl-ctrl-icon", this.button);
        this.button.title = "Zoom out";
        this.button.addEventListener("click", (e) => {
          const z = map.getZoom();
          let i;
          for (i = zoomLevels.length; i >= 0; i--) {
            if (zoomLevels[i] < z) {
              break;
            }
          }
          map.zoomTo(zoomLevels[i], { originalEvent: e });
        });
        return container;
      },
      onRemove: function () {
        this.button.remove();
      },
    };
  };

  const zi = new zoomIn();
  const zo = new zoomOut();

  map.addControl(zi, "top-left");
  map.addControl(zo, "top-left");
  return [zi, zo];
}
