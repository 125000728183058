import { objectProperties } from "./gameObjects";
import { addHoverEffect } from "./mapHelpers";
import {
  clone,
  combine,
  flatten,
  buffer,
  featureCollection,
  polygonToLine,
  point,
} from "@turf/turf";

import doubleClickZoom from "@maupalantir/mapbox-gl-draw/src/lib/double_click_zoom";
import * as Constants from "@maupalantir/mapbox-gl-draw/src/constants";
import DrawPolygon from "@maupalantir/mapbox-gl-draw/src/modes/draw_polygon";
import DrawLineString from "@maupalantir/mapbox-gl-draw/src/modes/draw_line_string";
import FreehandMode from './FreehandMode'
import FreehandLine from './FreehandLine'
export function addPredefinedObject(widget, objectType) {
  widget.modeSelected = "predefined";
  // widget.resetListener()
  const singleton = objectType.object.singleton;
  const currentPredefined = widget.predefined.features.filter((f) => {
    return (
      f.properties.objectType === objectType._id ||
      f.properties.nbsType === objectType._id
    );
  });

  const predefined = featureCollection(currentPredefined);
  let source = predefined;
  if (singleton) {
    source = combine(predefined);
  }
  if (!widget.map.getSource("source-predefined-" + objectType._id)) {
    widget.map.addSource("source-predefined-" + objectType._id, {
      type: "geojson",
      data: source,
      generateId: true,
    });
  }

  const layer = "layer-predefined-" + objectType._id;

  if (objectType.object.type === "Polygon") {
    widget.map.addLayer({
      id: layer,
      type: "fill",
      interactive: true,
      source: "source-predefined-" + objectType._id,
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          0.4,
        ],
      },
    });
  }

  if (objectType.object.type === "LineString") {
    widget.map.addLayer({
      id: layer,
      type: "line",
      interactive: true,
      source: "source-predefined-" + objectType._id,
      layout: {},
      paint: {
        "line-color": ["get", "color"],
        "line-width": 5,
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          0.4,
        ],
      },
    });
  }
  const clickCallback = (event) => {
    if (!event.features) return;

    let features = [];
    if (objectType.object.singleton) {
      features = flatten(event.features[0]).features;
    } else {
      features.push(event.features[0]);
    }
    widget.objectsInternalPrevious = clone(widget.objectsInternal);

    features.forEach((f) => {
      f.properties = objectProperties(
        objectType,
        f.properties,
        widget.additionalProperties
      );
      widget.objectsInternal.features.push(f);
    });

    widget.emit("created", featureCollection(features));
  };

  const hoverCallbacks = addHoverEffect(
    widget.map,
    "source-predefined-" + objectType._id,
    layer,
    clickCallback
  );

  widget.cleanupCallback = function () {
    widget.modeSelected = false;
    widget.map.off("click", layer, clickCallback);
    for (const e in hoverCallbacks) {
      if (hoverCallbacks.hasOwnProperty(e)) {
        widget.map.off(e, layer, hoverCallbacks[e]);
      }
    }
    if (widget.map.getLayer(layer)) widget.map.removeLayer(layer);
  };
}

export function addBuildingObject(widget, objectType) {
  const layer = "layer-buildings-" + objectType._id;
  widget.modeSelected = "building";
  widget.map.addLayer({
    id: layer,
    type: "fill",
    interactive: true,
    source: "buildings",
    paint: {
      "fill-color": ["get", "color"],
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        1,
        0.4,
      ],
    },
  });

  const clickCallback = function (event) {
    if (!event.features) return;

    let f = clone(event.features[0]);
    if (objectType.object.buffer) {
      f = buffer(f, -10, { units: "meters" });
    }
    if (objectType.object.type === "LineString") {
      f = polygonToLine(f);
    }

    widget.objectsInternalPrevious = clone(widget.objectsInternal);

    if (f) {
      f.properties = objectProperties(
        objectType,
        f.properties,
        widget.additionalProperties
      );
      delete f.properties.building;
      widget.objectsInternal.features.push(f);
      widget.emit("add_on_building", featureCollection([f]));
    }
  };

  const hoverCallbacks = addHoverEffect(
    widget.map,
    "buildings",
    layer,
    clickCallback
  );

  widget.cleanupCallback = function () {
    widget.modeSelected = false;
    widget.map.off("click", layer, clickCallback);
    for (const e in hoverCallbacks) {
      if (hoverCallbacks.hasOwnProperty(e)) {
        widget.map.off(e, layer, hoverCallbacks[e]);
      }
    }
    if (widget.map.getLayer(layer)) widget.map.removeLayer(layer);
  };
}

export function setDrawMode(widget, objectType) {
  widget.modeSelected = "draw_" + objectType._id;

  if ("draw_" + objectType._id in widget.draw.modes) {
    widget.draw.changeMode("draw_" + objectType._id);
  }

  widget.cleanupCallback = function () {
    widget.draw.trash();
    widget.modeSelected = false;
  };
}

export function generateObjects(widget, objectType) {
  widget.map.getCanvas().style.cursor = "pointer";
  widget.modeSelected = "generateObjects";

  const clickListener = function (event) {
    const center = point([event.lngLat.lng, event.lngLat.lat]);
    // widget.searchObject()

    widget.map.getCanvas().style.cursor = "";
    widget.objectsInternalPrevious = clone(widget.objectsInternal);
    const features = widget.generator.generateFeatures(
      widget,
      objectType._id,
      center,
      widget.additionalProperties
    );
    if (features) {
      widget.objectsInternal.features = widget.objectsInternal.features.concat(
        features.features
      );
      widget.emit("created", features);
    }
    widget.modeSelected = false;
  };
  const removeClickListener = function () {
    widget.map.off("mousedown", clickListener);
  };

  widget.listener = clickListener;
  widget.map.on("mousedown", clickListener);
  widget.map.on("mouseup", removeClickListener);

  widget.cleanupCallback = () => {
    widget.map.off("mousedown", clickListener);
    widget.map.off("mouseup", removeClickListener);
    widget.modeSelected = false;
  };
}

export function createDrawMode(objectType, additionalProperties = {}) {
  let drawMode = null;

  switch (objectType.object.type) {
    case "Polygon":
      drawMode = Object.assign({}, FreehandMode);
      drawMode.onSetup = function () {
        const polygon = this.newFeature({
            type: Constants.geojsonTypes.FEATURE,
            properties: objectProperties(objectType, {}, additionalProperties),
            geometry: {
                type: Constants.geojsonTypes.POLYGON,
                coordinates: [[]]
            }
        });

        this.addFeature(polygon);
        this.clearSelectedFeatures();

        // disable dragPan
        setTimeout(() => {
            if (!this.map || !this.map.dragPan) return;
            this.map.dragPan.disable();
        }, 0);

        this.updateUIClasses({ mouse: Constants.cursors.ADD });
        this.activateUIButton(objectType._id);
        this.setActionableState({
            trash: true
        });

        return {
            polygon,
            currentVertexPosition: 0,
            dragMoving: false
        };
      }
      break;
    case "LineString":
      drawMode = Object.assign({}, FreehandLine);
      drawMode.onSetup = function (opts) {
        opts = opts || {};
        const featureId = opts.featureId;

        let line;
        let currentVertexPosition;
        let direction = "forward";
        if (featureId) {
          line = this.getFeature(featureId);
          if (!line) {
            throw new Error(
              "Could not find a feature with the provided featureId"
            );
          }
          let from = opts.from;
          if (
            from &&
            from.type === "Feature" &&
            from.geometry &&
            from.geometry.type === "Point"
          ) {
            from = from.geometry;
          }
          if (
            from &&
            from.type === "Point" &&
            from.coordinates &&
            from.coordinates.length === 2
          ) {
            from = from.coordinates;
          }
          if (!from || !Array.isArray(from)) {
            throw new Error(
              "Please use the `from` property to indicate which point to continue the line from"
            );
          }
          const lastCoord = line.coordinates.length - 1;
          if (
            line.coordinates[lastCoord][0] === from[0] &&
            line.coordinates[lastCoord][1] === from[1]
          ) {
            currentVertexPosition = lastCoord + 1;
            // add one new coordinate to continue from
            line.addCoordinate(
              currentVertexPosition,
              ...line.coordinates[lastCoord]
            );
          } else if (
            line.coordinates[0][0] === from[0] &&
            line.coordinates[0][1] === from[1]
          ) {
            direction = "backwards";
            currentVertexPosition = 0;
            // add one new coordinate to continue from
            line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
          } else {
            throw new Error(
              "`from` should match the point at either the start or the end of the provided LineString"
            );
          }
        } else {
          line = this.newFeature({
            type: "Feature",
            properties: objectProperties(objectType, {}, additionalProperties),
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          });
          currentVertexPosition = 0;
          this.addFeature(line);
        }

        this.clearSelectedFeatures();
        doubleClickZoom.disable(this);
        // disable dragPan
        setTimeout(() => {
            if (!this.map || !this.map.dragPan) return;
            this.map.dragPan.disable();
        }, 0);
        this.updateUIClasses({ mouse: Constants.cursors.ADD });
        this.activateUIButton(objectType._id);
        this.setActionableState({
          trash: true,
        });

        return {
          line,
          currentVertexPosition,
          direction,
        };
      };
      break;
  }

  return drawMode;
}
